import React from "react"
import styled, { keyframes } from 'styled-components';

const gradientAnimation = keyframes`
  0% {
  	background-position: 0% 50%;
  }
  50% {
  	background-position: 100% 50%;
  }
  100% {
  	background-position: 0% 50%;
  }
`;

const Background = styled.div`
	background: linear-gradient(90deg, #c09fe2, #dfa3d7, #f4aacb, #ffb4c1, #ffc2bb, #ffd1bb, #ffe0c1, #ffeecd);
  	background-size: 400% 400%;
  	animation: ${gradientAnimation} 15s ease infinite;
    width: 100%;
    height: 130%;
    position: absolute;
    top:0;
    left:0;
    right:0;
    bottom:0;
`

export default function AnimatedGradientBackground({ children }) {
	return (<Background>{children}</Background>)
}