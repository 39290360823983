import React from "react"
import logo from './logo.svg';
import './App.css';
import Background from './components/AnimatedGradientBackground';
import Loader from './components/Loader';
import styled, { keyframes } from 'styled-components';
import * as DOMPurify from 'dompurify';

const Title = styled.h1`
  color: white;
  font-family: helvetica;
  margin-top: 40px;
  text-align: center;
`

const Subtitle = styled.h2`
  color: white;
  font-family: helvetica;
  margin-top: 30px;
  text-align: center;
  max-width: 500px;
  margin: auto;
`

const MadeWith = styled.h4`
  color: white;
  font-family: helvetica;
  margin-top: 12px;
  text-align: center;
  font-size: 12px;
`

const FormContainer = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 400px;
  min-width: 200px;
  margin: auto;
  padding: 20px;
  background-color: white;
  border-radius: 10px;
  margin-top: 60px;
  background-color: #ffffff;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  padding: 32px;
`

const Label = styled.label`
  margin-top: 10px;
  font-family: helvetica;
  margin-bottom: 8px;
`

const Input = styled.input`
  padding: 10px;
  border: 2px solid gray;
  border-radius: 5px;
  padding: 12px;
  border: 1px solid #ebeef5;
  border-radius: 4px;
  margin-bottom: 16px;
  font-size: 16px;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  &:focus {
      border-color: #1a73e8;
      box-shadow: 0 0 0 0.2rem rgba(26, 115, 232, 0.25);
      outline: none;
  }
`

const Button = styled.button`
  background-color: #c09fe2;
  color: #ffffff;
  padding: 12px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 16px;
  transition: background-color 0.15s ease-in-out;
  text-align: center;
  margin-top: 20px;
  display: ${props => props.isVisible ? "block" : "none"};
  &:hover {
      background-color: #AC8FCB;
  }
`

const ButtonHasLoader = styled(Button)`
  pointer-events: none;
`

const PopUpBackground = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0,0,0,0.5);
  overflow: scroll;
  display: ${props => props.isVisible ? "block" : "none"};
  z-index: ${props => props.isVisible ? "999" : "0"};
  pointer-events: ${props => props.isVisible ? "auto" : "none"};
`

const popUpwards = keyframes`
0% {
  -webkit-transform:matrix(.97,0,0,1,0,12);
  transform:matrix(.97,0,0,1,0,12);
  opacity:0
}
20% {
  -webkit-transform:matrix(.99,0,0,1,0,2);
  transform:matrix(.99,0,0,1,0,2);
  opacity:.7
}
40% {
  -webkit-transform:matrix(1,0,0,1,0,-1);
  transform:matrix(1,0,0,1,0,-1);
  opacity:1
}
70% {
  -webkit-transform:matrix(1,0,0,1,0,0);
  transform:matrix(1,0,0,1,0,0);
  opacity:1
}
100% {
  -webkit-transform:matrix(1,0,0,1,0,0);
  transform:matrix(1,0,0,1,0,0);
  opacity:1
}`;

const PopUpContainer = styled.div`
  background-color: #ffffff;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  padding: 32px;
  display: flex;
  flex-direction: column;
  width: 400px;
  margin: auto;
  background-color: white;
  border-radius: 10px;
  margin-top: 60px;
  animation: ${popUpwards} 2s forwards ease-in-out;
`

const Song = styled.p`
  color: grey;
  font-size: 18px;
  font-family: helvetica;
`

const TinyText = styled.p`
  color: ${props => props.isVisible ? "grey" : "white"};
  font-size: 12px;
  font-family: helvetica;
  text-align: center;
  padding-bottom: 0px;
  margin-bottom: 0px;
`


class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      plot: ``,
      words: ``,
      artist: ``,
      song: ``,
      showSubmit: true,
      showLoading: false,
      showPopUp: false,
    }
  }

  handleInputChange(e, property) {
    const inputValue = e.target.value;
    this.setState({
      [property]: DOMPurify.sanitize(inputValue),
    });
  }

  async handleSubmit(e) {
    e.preventDefault();
    this.setState({
      showSubmit: false,
      showLoading: true,
    });

    let song;

    try {
      const response = await this.getSong();
      song = response.song;
      const cleanSong = DOMPurify.sanitize(song, { USE_PROFILES: { html: true } })
      this.setState({
        showPopUp: true,
        showLoading: false,
        showSubmit: true,
        song: cleanSong,
      });
    }
    catch(err) {
      this.setState({
        showPopUp: true,
        showLoading: false,
        showSubmit: true,
        song: '🤭 Oops there was a problem making your song. Please try again.',
      });
    }
  }

  handleClose(e) {
    e.preventDefault();
    this.setState({
      showSubmit: true,
      showLoading: false,
      showPopUp: false,
      song: ``,
    });
  }

  async getSong() {
    const { plot, words, artist } = this.state;

      //http://localhost:3000/ai/song/
      const response = await fetch(`https://songbirdapi.herokuapp.com/ai/song/${encodeURIComponent(artist)}/${encodeURIComponent(plot)}/${encodeURIComponent(words)}`, {
          method: 'GET',
          headers: {
              'Content-Type': 'application/json',
              'Accept': 'application/json',
          }
      });
      return response.json()
  }

  render() {
    const { plot, words, artist, song, showSubmit, showLoading, showPopUp } = this.state;

    return (
    <Background>
      <Title>SongMagic.co</Title>
      <Subtitle>Create songs in the style of your favorite artist about whatever you want</Subtitle>
      <FormContainer>
        <Label>Song plot:</Label>
        <Input
          type="text" 
          id="plot"
          placeholder="i.e.: Two friends building a snowman"
          value={plot}
          onChange={e => this.handleInputChange(e, "plot")}/>

        <Label>Words to include in song:</Label>
        <Input
          type="text"
          id="words"
          placeholder="i.e.: iridescent"
          value={words}
          onChange={e => this.handleInputChange(e, "words")}/>

        <Label>Artist to mimic:</Label>
        <Input
          type="text"
          id="artist"
          placeholder="i.e.: Morrissey"
          value={artist}
          onChange={e => this.handleInputChange(e, "artist")}/>

        <TinyText isVisible={this.state.showLoading}>This may take a minute...</TinyText>
        <Button
          type="submit"
          onClick={e => this.handleSubmit(e)}
          isVisible={this.state.showSubmit}>Submit</Button>
        <ButtonHasLoader isVisible={this.state.showLoading} ><Loader/></ButtonHasLoader>
      </FormContainer>

      <PopUpBackground isVisible={showPopUp}>
        <PopUpContainer isVisible={showPopUp}>
          <Song id="popup-text" dangerouslySetInnerHTML={{__html: song}}></Song>
          <Button isVisible={showPopUp} onClick={e => this.handleClose(e)}>Close</Button>
        </PopUpContainer>
      </PopUpBackground>

      <MadeWith>✨ Built with GPT3 ✨</MadeWith>
    </Background>
  );
  }
}

export default App;
