import React from "react"
import styled, { keyframes } from 'styled-components';

const spin = keyframes`
  100% {
    transform: rotate(360deg);
  }
`;

const Container = styled.span`
  display: inline-block;
  width: 10px;
  height: 10px;
  border: 3px solid white;
  border-radius: 50%;
  border-top-color: transparent;
  animation: ${spin} 1s linear infinite;
`

export default function Loader({ children }) {
	return <Container />
}